import { defineStore } from "pinia";
import axios from 'axios'
import { useRuntimeConfig } from "nuxt/app";

export const useListsStore = defineStore("lists", {
	state: () => ({
        flatCategories: [] as any[],
        customerSources: [] as any[],
        customerCategories: [] as any[],
        customers: [] as any[],
        users: [] as any[],
        countries: [] as any[],
        cities: [] as any[],
	}),
    getters: {
        flatCategoriesGetter: (state) => () => state.flatCategories,
        customerSourcesGetter: (state) => () => state.customerSources,
        customerCategoriesGetter: (state) => () => state.customerCategories,
        customersGetter: (state) => () => state.customers,
        usersGetter: (state) => () => state.users,
        countriesGetter: (state) => () => state.countries,
        citiesGetter: (state) => () => state.cities,
    },
	actions: {
        getVcrmAccountToken() {
			if (!document) {
				return false;
			}
			const vue_cookie = document.cookie.split("; ").find((row) => row.startsWith("vcrm-account-token"));
			if (vue_cookie != undefined && vue_cookie.split("=")[1] != "") {
				return vue_cookie.split("=")[1];
			}
		},

        async fetchFlatCategories() {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}

            axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            await axios.get(`${accountApiUrl}/lists/categories/flat`)
                .then((res) => {
                    this.flatCategories = res.data
                    return res.data
                })
                .catch(err => {
                    this.flatCategories = []
                    return []
                })
        },
        async fetchCustomerSources() {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;
			}

            axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            await axios.get(`${accountApiUrl}/lists/customer-sources`)
                .then((res) => {
                    this.customerSources = res.data
                    return res.data
                })
                .catch(err => {
                    this.customerSources = []
                    return []
                })
        },
        async fetchCustomerCategories() {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;
			}

            axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            await axios.get(`${accountApiUrl}/lists/customer-categories`)
                .then((res) => {
                    this.customerCategories = res.data
                    return res.data
                })
                .catch(err => {
                    this.customerCategories = []
                    return []
                })
        },

        async fetchCustomers(user_id='') {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}

            axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            const query = Number(user_id) ? `?user_id=${Number(user_id)}`: ''

            await axios.get(`${accountApiUrl}/lists/customers${query}`)
                .then((res) => {
                    this.customers = res.data
                    return res.data
                })
                .catch(err => {
                    this.customers = []
                    return []
                })
        },
        async fetchUsers() {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;
			}

            axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            await axios.get(`${accountApiUrl}/lists/users`)
                .then((res) => {
                    this.users = res.data
                    return res.data
                })
                .catch(err => {
                    this.users = []
                    return []
                })
        },
        async fetchCountries(query: any) {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;
			}

            axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            const url = new URL(`${accountApiUrl}/lists/countries`);

            if (query) {
                for (const key in query) {
                    const value = query[key];
                    url.searchParams.append(key, value)
                }
            }

            await axios.get(url.toString())
                .then((res) => {
                    this.countries = res.data
                    return res.data
                })
                .catch(err => {
                    this.countries = []
                    return []
                })
        },
        async fetchCitiesInCountry(country_id: number) {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;
			}

            axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            const url = new URL(`${accountApiUrl}/lists/cities/${country_id}`);

            await axios.get(url.toString())
                .then((res) => {
                    this.cities = res.data
                    return res.data
                })
                .catch(err => {
                    this.cities = []
                    return []
                })
        },
    }
});